<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar
          color="primary"
          dark
        >
          <span>{{ $route.params.customer }} Customer Details</span>
          <v-spacer></v-spacer>
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-toolbar>
        <v-alert
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in custPostErrors"
          :key="index"
        >
          <span class="text-uppercase ">{{ item.first() }}</span>
        </v-alert>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-autocomplete
          label="Product Category"
          :items="prdCatList"
          :loading="prdCatGetting"
          v-model="productCategoryId"
          item-value="id"
        >
          <template v-slot:selection="{ item }">
            <span class="primary--text">{{ item.id }}</span><span class="blue-grey--text mx-1 text-caption">{{ item.name }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="primary--text">{{ item.id }}</span><span class="blue-grey--text mx-1 text-caption">{{ item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Capacity"
          type="number"
          :loading="custDetGetting"
          v-model="capacity"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Capacity Unit"
          v-model="capacityUnit"
          :loading="custDetGetting"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="EX Supplier"
          v-model="exSupplier"
          :loading="custDetGetting"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Potential"
          v-model="potential"
          :loading="custDetGetting"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-checkbox
          :loading="custDetGetting"
          v-model="confirmed"
          label="Confirmed"
        ></v-checkbox>
      </v-col>
      <v-col
        lg="4"
        cols="12"
      >
        <v-text-field
          label="Date Start"
          v-model="dateStart"
          type="date"
          :loading="custDetGetting"
        ></v-text-field>
      </v-col>
      <v-col
        lg="1"
        cols="12"
      >
        <v-btn
          color="primary"
          :loading="custPosting"
          block
          dark
          @click="storeCustDet"
        >Save</v-btn>
      </v-col>
      <v-col
        lg="1"
        cols="12"
      >
        <v-btn
          color="red"
          block
          dark
          @click="$router.push('/customers')"
        >Cancel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import custRepository from '@/repositories/customer.repository'
import prdCatRepository from '@/repositories/product-category.repository'
import { postVars, errorHandler, getVars } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const storeDelay = searchDelay()
const getPrdCatDelay = searchDelay()
const getCustDetDelay = searchDelay()

const inputVars = () => ({
  productCategoryId: null,
  capacity: null,
  capacityUnit: null,
  exSupplier: null,
  potential: null,
  confirmed: false,
  dateStart: null,
})

export default {
  name: 'EditCustomerDetail',
  created () {
    this.getPrdCat()
    this.getCustDet()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...postVars('cust'),
      ...getVars('prd-cat'),
      ...getVars('cust-det'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  methods: {
    getCustDet () {
      if (this.custDetGetting) return
      this.custDetGetting = true
      this.custDetGetErrors = []
      getCustDetDelay(() => {
        const customerId = this.$route.params.customer
        custRepository.detail(customerId)
          .then(this.populateInputs)
          .catch(e => { this.custDetGetErrors = errorHandler(e) })
          .then(() => { this.custDetGetting = false })
      })
    },
    populateInputs ({ data }) {
      Object.keys(inputVars()).forEach(e => {
        this[e] = data[camelToSnakeCase(e)]
      })
    },
    getPrdCat () {
      if (this.prdCatGetting) return
      this.prdCatGetting = true
      this.prdCatGetErrors = []
      getPrdCatDelay(() => {
        prdCatRepository.index()
          .then(({ data }) => { this.prdCatList = data })
          .catch(e => { this.prdCatGetErrors = errorHandler(e) })
          .then(() => { this.prdCatGetting = false })
      })
    },
    websocketEvents () {
      this.echo.channel('product.categories.updated')
        .listen('ProductCategoryUpdatedEvent', e => { this.getPrdCat() })
    },
    storeCustDet () {
      if (this.custPosting) return
      this.custPosting = true
      this.custPostErrors = []
      storeDelay(() => {
        const customerId = this.$route.params.customer
        const data = this.getInputData()
        custRepository.updateDetail(customerId, data)
          .then(() => { this.$router.push({ name: 'customers' }) })
          .catch(e => { this.custPostErrors = errorHandler(e) })
          .then(() => { this.custPosting = false })
      })
    },
    getInputData () {
      return {
        product_category_id: this.productCategoryId,
        capacity: this.capacity,
        capacity_unit: this.capacityUnit,
        ex_supplier: this.exSupplier,
        potential: this.potential,
        confirmed: this.confirmed,
        date_start: this.dateStart,
      }
    },
  },
}
</script>
